'use client';
import { useState } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import chevronRight from 'public/images/icons/chevronRight.svg';
import style from './style.module.css';
import { TagSearch, SearchLabel } from 'lib/types';
import { usePathname } from 'next/navigation';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';

function isActiveSearch(pathName: string, searchId: string | null) {
  return searchId ? pathName.includes(searchId) : false;
}

interface TagContainerProps {
  searches: SearchLabel[];
  tag: string;
  closeNavCB?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export default function TagContainer({
  tag,
  searches,
  closeNavCB,
}: TagContainerProps) {
  const pathName = usePathname();
  const [isOpen, setIsOpen] = useState(true);
  const [user] = useAuth();

  return (
    <div className={style.tagSearch}>
      <div
        className={style.tag}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className={style.tagText}>#{tag}</span>
        <Image
          className={classNames(style.chevron, {
            [style.chevronOpen]: isOpen,
          })}
          src={chevronRight}
          alt="Chevron"
        ></Image>
      </div>
      <ul
        className={classNames(style.searchList, {
          [style.searchListShow]: isOpen,
        })}
      >
        {searches.map((search) => (
          <li
            key={search.id}
            className={classNames(style.search, {
              [style.activeSearch]: isActiveSearch(pathName, search.queryId),
            })}
          >
            <Link
              href={`/search/${search.queryId}`}
              onClick={(ev) => {
                if (closeNavCB) closeNavCB(ev);
                analytics.clickedPreviousSearch(user, search);
              }}
            >
              {search.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
