'use client';
import { MouseEvent } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Button from 'components/Button';
import TagContainer from './tagContainer';
import EmptyState from './emptyState';
import Logo from 'public/images/cleeai-logo-research.svg';

import closeIcon from 'public/images/icons/close.svg';
import addIconBlue from 'public/images/icons/groupAddBlue.svg';

import style from './style.module.css';

import { useSearch } from 'providers/SearchProvider';
import { useStateItem } from 'providers/stateItem';
import InviteFriendsModal from 'components/InviteFriendsModal';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';

interface SidebarProps {
  closeNavCB: (e: MouseEvent) => void;
}

export function Sidebar({ closeNavCB }: SidebarProps) {
  const [user] = useAuth();
  const search = useSearch();
  const taggedSearches = search?.savedSearches.get() || [];
  const isInviteModalOpen = useStateItem(false);

  return (
    <div className={style.container}>
      <Image
        src={closeIcon}
        className={style.closeIcon}
        alt="Close Icon"
        onClick={closeNavCB}
      />
      <Link href="/">
        <Image src={Logo} className={style.logoSidebar} alt="Logo" />
      </Link>
      <Button
        variant="tertiary"
        onClick={() => {
          isInviteModalOpen.set(true);
          analytics.generic(user, 'Invite Friends button clicked');
        }}
        className={style.inviteBtn}
      >
        <Image src={addIconBlue} alt="Add Friends Icon"></Image>
        دعوة الأصدقاء
      </Button>
      <p className={style.subHeading}>عمليات البحث السابقة</p>
      {taggedSearches.length ? (
        <div className={style.searchItemsList}>
          <ul className={style.tagSearches}>
            {taggedSearches.map((tagSearch) => (
              <li key={tagSearch.tag}>
                <TagContainer
                  searches={tagSearch.searches}
                  tag={tagSearch.tag}
                  closeNavCB={closeNavCB}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <EmptyState />
      )}
      <InviteFriendsModal
        isModalOpen={isInviteModalOpen.get()}
        onCloseCB={() => isInviteModalOpen.set(false)}
      />
    </div>
  );
}
