import classNames from 'classnames';
import { ReactNode } from 'react';
import Link from 'next/link';

import style from './style.module.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'button';
  variant?: 'primary' | 'secondary' | 'tertiary';
  isFullWidth?: boolean;
  size?: 'large' | 'small';
  children: string | ReactNode;
  className?: string;
  onClickHandler?: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  href?: string;
}

export default function Button({
  type = 'submit',
  variant = 'primary',
  isFullWidth = false,
  size = 'large',
  children,
  className = '',
  onClickHandler,
  href = '',
  ...attr
}: Props) {
  const calculatedClassName = classNames(
    style.button,
    style[variant],
    style[size],
    { [style.fill]: isFullWidth },
    {
      [className]: Boolean(className),
    },
  );

  if (Boolean(href)) {
    return (
      <Link href={href} className={calculatedClassName}>
        {children}
      </Link>
    );
  }

  return (
    <button
      className={calculatedClassName}
      type={type}
      onClick={onClickHandler}
      {...attr}
    >
      {children}
    </button>
  );
}
