import style from './style.module.css';

import { useMemo } from 'react';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';
import { useStateItem } from 'providers/stateItem';
import { useSearch } from 'providers/SearchProvider';
import { emailConstraint } from 'utils/validationConstraints';
import { sendEmail } from 'utils/sendEmail';
import { toast } from 'sonner';
import useDeviceAndOrientation from 'hooks/useDeviceAndOrientation';

import Image from 'next/image';
import Modal from 'components/Modal';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';

import copyIconWhite from 'public/images/icons/contentCopyWhite.svg';
import mailIconWhite from 'public/images/icons/mailWhite.svg';
import shareIcon from 'public/images/icons/share.svg';

interface InviteFriendsModalProps {
  isModalOpen: boolean;
  onCloseCB: () => void;
}

export default function InviteFriendsModal({
  isModalOpen,
  onCloseCB,
}: InviteFriendsModalProps) {
  const [user] = useAuth();
  const search = useSearch();
  const email = useStateItem('');
  const error = useStateItem('');
  const isLoading = useStateItem(false);
  const inviteLink = search?.share.generateInviteLink();
  const isMobile = useDeviceAndOrientation()['device'] === 'mobile';
  const iconsHight = isMobile ? 30 : 20;
  const inviterName =
    user?.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : `${user?.userName}`;

  const emailMessageBody = useMemo(() => {
    if (typeof window === 'undefined') return '';
    return `
    <p>Hey there 👋,</p>
    <p>${inviterName} يريد منك التسجيل في كلي والانضمام إلى ملايين المحترفين الذين يستخدمون البحث المدعوم بالذكاء الاصطناعي </p>
    <ul>
      <li>كن منتجًا للغاية ⚡</li>
      <li>احصل على استجابات عالية الجودة 😊</li>
      <li>تجنب البحث اللانهائي 👍</li>
    </ul>
    <p><a href="www.cleeai.com">اطلب حسابك المجاني</a></p>
    <p>تذكر أن تشكر ${inviterName}!</p>
    <p>CleeAI<br>
    اسأل أي شيء، وثق بكل شيء.<br>
    محرك إجابة مدعوم بالذكاء الاصطناعي.<br>
    <a href="www.cleeai.com">ar.cleeai.com</a></p>
  `;
  }, [inviterName]);

  const validateEmail = () => {
    const validation = emailConstraint.safeParse(email.get());

    if (!validation.success) {
      const errors = validation?.error?.flatten();
      error.set(errors?.formErrors[0]);

      return false;
    }
    error.set('');
    return true;
  };

  const onChangeCB = (ev: React.ChangeEvent<HTMLInputElement>) => {
    Boolean(error.get()) && error.set('');
    email.set(ev.target.value);
  };

  const handleSendInvite = async () => {
    const emailAddress = email.get();
    Boolean(error.get()) && error.set('');
    isLoading.set(true);

    if (emailAddress === user?.userName) {
      error.set('عذراً، لا يمكنك دعوة نفسك.');
      isLoading.set(false);
      return;
    }

    const isValid = validateEmail();
    if (!isValid) {
      isLoading.set(false);
      return;
    }

    try {
      await sendEmail(
        emailAddress,
        `'تخطي قائمة الانتظار. دعوة من ${inviterName}`,
        emailMessageBody,
      );
      toast.success(`تم إرسال البريد الإلكتروني بنجاح إلى ${email.get()}`);
      email.set('فشل البريد الإلكتروني');
      analytics.invitedUser(user, [email.get()]);
    } catch (error) {
      error.set(error);
      analytics.generic(user, '');
    } finally {
      isLoading.set(false);
    }
  };

  const closeModal = () => {
    Boolean(email.get()) && email.set('');
    Boolean(error.get()) && error.set('');
    onCloseCB();
    analytics.generic(user, 'Invite Friends modal closed');
  };

  return (
    <>
      <Modal
        className={style.modal}
        isOpen={isModalOpen}
        onCloseCB={closeModal}
        title="دعوة الأصدقاء إلى كلي"
      >
        <div className={style.modalBody}>
          <Label htmlFor="inviteLink">Invitation Link</Label>
          <div className={style.inputContainer}>
            <Input
              type="text"
              name="inviteLink"
              value={inviteLink ?? ''}
              className={style.input}
              readOnly
              onChangeCB={() => undefined}
            ></Input>
            <Button
              className={style.btn}
              onClick={() => {
                search?.share.shareInvite();
                analytics.sharedInviteLink(user);
              }}
            >
              <Image
                src={isMobile ? shareIcon : copyIconWhite}
                alt="Copy Icon"
                height={iconsHight}
              ></Image>
              {isMobile ? '' : 'Copy link'}
            </Button>
          </div>
          <Label htmlFor="inviteLink">Invite by Email</Label>
          <p className={style.inviteParagraph}>
            Type emails, separated by space or comma
          </p>
          <div className={style.inputContainer}>
            <Input
              type="text"
              name="inviteLink"
              value={email.get()}
              className={style.input}
              onChangeCB={onChangeCB}
            ></Input>
            <Button
              className={style.btn}
              onClick={handleSendInvite}
              disabled={isLoading.get()}
            >
              <Image
                src={mailIconWhite}
                alt="Mail Icon"
                height={iconsHight}
              ></Image>
              {isMobile ? '' : 'إرسال دعوة'}
            </Button>
          </div>
          {Boolean(error) && (
            <ErrorMessage className={style.errorMessage}>
              {error.get()}
            </ErrorMessage>
          )}
        </div>
      </Modal>
    </>
  );
}
