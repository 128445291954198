'use client';

import { useRef, useEffect, ReactNode } from 'react';
import classNames from 'classnames';
import Image from 'next/image';

import closeIcon from 'public/images/icons/close.svg';

import style from './style.module.css';

interface Props {
  title?: string;
  isOpen: boolean;
  onCloseCB: (
    event:
      | MouseEvent
      | KeyboardEvent
      | React.MouseEvent<HTMLButtonElement>
      | undefined,
  ) => void;
  children: ReactNode;
  actions?: ReactNode;
  className?: string;
}

export default function Modal({
  title,
  isOpen,
  onCloseCB,
  children,
  actions,
  className = '',
}: Props) {
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleMousedown(ev: MouseEvent) {
      const target = ev.target as HTMLElement;
      if (!modalContentRef?.current?.contains(target)) {
        onCloseCB(ev);
      }
    }

    function handleEscapeKeyPress(ev: KeyboardEvent) {
      if (ev.key === 'Escape') {
        onCloseCB(ev);
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleMousedown);
      document.addEventListener('keydown', handleEscapeKeyPress);
    }

    /** @info: remove event listeners when modal is unmounted */
    return () => {
      document.removeEventListener('mousedown', handleMousedown);
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [isOpen, onCloseCB]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [isOpen]);

  return (
    <div
      id="modal-container"
      tabIndex={-1}
      className={classNames(
        style.container,
        { [style['isHidden']]: !isOpen },
        {
          [className]: Boolean(className),
        },
      )}
    >
      <div className={style.overlay} />
      <div
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={style.modal}
        ref={modalContentRef}
      >
        <button
          className={style.close}
          onClick={(ev: React.MouseEvent<HTMLButtonElement>) => onCloseCB(ev)}
          aria-label="Close the modal"
        >
          <Image src={closeIcon} alt="Close Icon" />
        </button>
        {title && (
          <div className={style.titleSection}>
            <h1 id="modalTitle" className={style.title}>
              {title}
            </h1>
          </div>
        )}
        <div className={style.body}>{children}</div>
        {actions && <div className={style.actions}>{actions}</div>}
      </div>
    </div>
  );
}
