'use client';

import style from './style.module.css';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import Button from 'components/Button';

import Logo from 'public/images/cleeai-logo-research.svg';

export default function Navbar() {
  const pathName = usePathname();
  const withLogo = pathName.includes('search');

  return (
    <nav
      className={classNames(
        {
          [style.navTopLogo]: withLogo,
        },
        {
          [style.navbar]: !withLogo,
        },
      )}
    >
      <div className={style.container}>
        <div className={style.leftArea}>
          {withLogo && (
            <Link href="/">
              <Image src={Logo} className={style.navLogoUnauth} alt="Logo" />
            </Link>
          )}
        </div>
        <div className={style.rightArea}>
          <div
            className={classNames(style.unauthNavRight, {
              [style.navMWithLogo]: withLogo,
            })}
          >
            <Button
              type="submit"
              variant="primary"
              href="/create-account"
              className={classNames(style.createAccountBtn, {
                [style.createAccountBtnWithLogo]: !withLogo,
              })}
            >
              سجل الآن
            </Button>
            <Button
              type="submit"
              variant="secondary"
              href="/login"
              className={style.createAccountBtn}
            >
              تسجيل الدخول
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
}
