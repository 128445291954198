import { ChangeEvent, FormEvent, useState } from 'react';
import classNames from 'classnames';
import { z } from 'zod';
import { toast } from 'sonner';

import Button from 'components/Button';
import Label from 'components/Label';
import Modal from 'components/Modal';
import Input from 'components/Input';
import ErrorMessage from 'components/ErrorMessage';
import { trpc } from 'lib/trpc';
import useAuth from 'hooks/useAuth';
import { ServerTypes } from 'lib/types';
import { analytics } from 'lib/analytics';

import { createPhoneNumberConstraints } from 'utils/validationConstraints';

import style from './style.module.css';

interface AccountSettingsModalProps {
  isOpen: boolean;
  onCloseCB: () => void;
}

interface UserDetails {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  role: string;
  team: string;
}

const defaultFieldErrors = {
  firstName: [],
  lastName: [],
  phoneNumber: [],
  companyName: [],
  role: [],
  team: [],
};

const requiredErrorMessage = 'is required';

const UserDetailsSchema = z.object({
  firstName: z.string().min(1, `First name ${requiredErrorMessage}`),
  lastName: z.string().min(1, `Last name ${requiredErrorMessage}`),
  phoneNumber: z.string().optional(),
  companyName: z.string().optional(),
  team: z.string().optional(),
  role: z.string().optional(),
});

export default function AccountSettingsModal({
  isOpen,
  onCloseCB,
}: AccountSettingsModalProps) {
  const [user, setUser] = useAuth();

  const {
    id,
    authUserId,
    userName,
    firstName,
    lastName,
    phoneNumber,
    companyName,
    role,
    team,
  } = user as ServerTypes.UserWithProfessions;

  const [formFields, setFormFields] = useState<UserDetails>({
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    companyName: companyName || '',
    role: role || '',
    team: team || '',
  });

  const [fieldErrors, setFieldErrors] = useState<{
    firstName?: string[];
    lastName?: string[];
    phoneNumber?: string[];
    companyName?: string[];
    role?: string[];
    team?: string[];
  }>(defaultFieldErrors);

  const [emailError, setEmailError] = useState('');
  const [genericError, setGenericError] = useState('');

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    setFormFields((previous) => ({ ...previous, [name]: value }));
    setFieldErrors(defaultFieldErrors);
  };

  const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    Boolean(genericError) && setGenericError('');
    Boolean(emailError) && setEmailError('');
    const validation = UserDetailsSchema.safeParse(formFields);
    if (!validation.success) {
      const validationErrors = validation?.error?.flatten();
      setFieldErrors(validationErrors?.fieldErrors);
      return;
    } else {
      try {
        const updatedUser = await trpc.user.update.mutate({
          id,
          authUserId,
          ...formFields,
        });
        toast.success('User details successfully updated!');

        setUser(updatedUser as ServerTypes.UserWithProfessions);
        onCloseCB();
      } catch (error) {
        setGenericError(
          'User details update failed. Please try again later or contact support.',
        );
      } finally {
        setFieldErrors(defaultFieldErrors);
      }
    }
  };

  return (
    <div
      onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
        ev.stopPropagation();
      }}
    >
      <Modal title="الإعدادات" isOpen={isOpen} onCloseCB={onCloseCB}>
        {/**@info email address is set outside the form as its value shouldn't be editable */}
        <div
          className={style.emailAddressContainer}
          onClick={() =>
            setEmailError(
              'لا يمكن تغيير عنوان البريد الإلكتروني. هذا الحقل غير قابل للتحرير.',
            )
          }
          aria-readonly
        >
          <Label htmlFor="email">الإيميل</Label>
          <Input
            type="text"
            name="Email"
            id="email"
            value={userName}
            onChangeCB={() => undefined}
            disabled
            readOnly
            onBlur={() => {
              Boolean(emailError) && setEmailError('');
            }}
            className={style.emailInput}
            errorMessage={emailError}
          ></Input>
        </div>
        <form className={style.form} onSubmit={handleSubmit}>
          <fieldset className={style.userDetails}>
            <div className={style.inputContainer}>
              <Label htmlFor="firstName">الاسم الأول</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={formFields.firstName}
                onChangeCB={handleChange}
                hasError={Boolean(fieldErrors?.firstName?.length)}
                errorMessage={fieldErrors?.firstName?.[0] ?? ''}
              />
            </div>
            <div className={style.inputContainer}>
              <Label htmlFor="lastName">الاسم الأخير</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={formFields.lastName}
                onChangeCB={handleChange}
                hasError={Boolean(fieldErrors?.lastName?.length)}
                errorMessage={fieldErrors?.lastName?.[0] ?? ''}
              />
            </div>
            <div className={style.inputContainer}>
              <Label htmlFor="phoneNumber">رقم هاتف</Label>
              <Input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={formFields.phoneNumber}
                onChangeCB={handleChange}
                hasError={Boolean(fieldErrors?.phoneNumber?.length)}
                errorMessage={fieldErrors?.phoneNumber?.[0]}
              />
            </div>
            <div className={style.inputContainer}>
              <Label htmlFor="companyName">اسم الشركة</Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                value={formFields.companyName}
                onChangeCB={handleChange}
                hasError={Boolean(fieldErrors?.companyName?.length)}
                errorMessage={fieldErrors?.companyName?.[0] ?? ''}
              />
            </div>
            <div className={style.inputContainer}>
              <Label htmlFor="role">الوظيفة</Label>
              <Input
                type="text"
                name="role"
                id="role"
                value={formFields.role}
                onChangeCB={handleChange}
                hasError={Boolean(fieldErrors?.role?.length)}
                errorMessage={fieldErrors?.role?.[0] ?? ''}
              />
            </div>
            <div className={style.inputContainer}>
              <Label htmlFor="team">الفريق</Label>
              <Input
                type="text"
                name="team"
                id="team"
                value={formFields.team}
                onChangeCB={handleChange}
                hasError={Boolean(fieldErrors?.team?.length)}
                errorMessage={fieldErrors?.team?.[0] ?? ''}
              />
            </div>
          </fieldset>
          <fieldset className={style.accountSettingsBtns}>
            <Button type="submit" variant="primary" className={style.submitBtn}>
              تحديث الملف الشخصي
            </Button>
            <Button
              variant="secondary"
              className={style.logout}
              href="/logout"
              onClickHandler={() => {
                analytics.signedOut(user);
              }}
            >
              تسجيل الخروج
            </Button>
          </fieldset>
          {Boolean(genericError) && <ErrorMessage>{genericError}</ErrorMessage>}
        </form>
      </Modal>
    </div>
  );
}
