'use client';
import { useState } from 'react';

import style from './style.module.css';
import Button from 'components/Button';
import SearchBox from 'components/SearchBox';
import useAuth from 'hooks/useAuth';
import { ServerTypes } from 'lib/types';
import { useSearch } from 'providers/SearchProvider';
import { QuestionTooltip } from './questionTooltip';
import { usePathname } from 'next/navigation';
import AccountSettingsModal from 'containers/AccountSettingsModal';
import useDeviceAndOrientation from 'hooks/useDeviceAndOrientation';
import classNames from 'classnames';

function getUserInitials(
  user: Partial<ServerTypes.UserWithProfessions> | null,
) {
  if (user) {
    const { firstName, lastName, userName } = user;
    const isFullName = firstName && lastName;
    return (
      isFullName
        ? firstName[0] + lastName[0]
        : userName
          ? userName.slice(0, 2)
          : 'NA'
    ).toUpperCase();
  }
}

function getUserName(user: Partial<ServerTypes.UserWithProfessions> | null) {
  if (user) {
    const { firstName, lastName, userName } = user;
    const isFullName = firstName && lastName;
    const result = isFullName
      ? firstName + ' ' + lastName
      : userName
        ? userName
        : 'User';
    return result.split('@')[0];
  }
}

export function ChatInputBoxAuth() {
  const [isShowUserProfileModal, setIsShowUserProfileModal] = useState(false);
  const [user] = useAuth();
  const search = useSearch();
  const isMobile = useDeviceAndOrientation()['device'] == 'mobile';
  const pathName = usePathname();
  const isHome = pathName == '/';

  return (
    <div className={style.searchboxContainer}>
      <div className={style.searchboxLeft}>
        <div onClick={() => setIsShowUserProfileModal(!isShowUserProfileModal)}>
          <div className={style.userWithName}>
            <div className={style.userInitialsContainer}>
              <p className={style.userInitials}>{getUserInitials(user)}</p>
            </div>
            <p className={style.mHide}>{getUserName(user)}</p>
          </div>
          <AccountSettingsModal
            isOpen={isShowUserProfileModal}
            onCloseCB={() => setIsShowUserProfileModal(false)}
          />
        </div>
      </div>
      <div className={style.searchboxRight}>
        <div
          className={classNames(style.searchboxInner, {
            [style.noSearch]: isHome,
          })}
        >
          <Button
            variant="primary"
            className={style.discoverBtn}
            onClick={() => search?.discovery.perform()}
            disabled={search?.discovery.isDisabled()}
          >
            {search?.discovery.getButtonText()}
          </Button>
          {!isHome && <SearchBox />}
        </div>
        {!isMobile && (
          <QuestionTooltip
            text={
              search?.discovery.isDisabled()
                ? 'انتظر لحظة واحدة. سأكون جاهزاً خلال 30 ثانية'
                : search?.discovery.getTooltip() || ''
            }
          />
        )}
      </div>
    </div>
  );
}
