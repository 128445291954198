import { ReactNode, ComponentPropsWithoutRef } from 'react';
import style from './style.module.css';

interface Props extends ComponentPropsWithoutRef<'label'> {
  children: ReactNode;
}

export default function Label({ children, ...attr }: Props) {
  return (
    <label className={style.label} {...attr}>
      {children}
    </label>
  );
}
