import Navbar from 'components/Navbar/unauth';
import { FC } from 'react';
import style from './style.module.css';

interface UnAuthLayoutProps {
  Content: FC;
  Footer?: FC;
}

export function UnauthLayout(props: UnAuthLayoutProps) {
  const { Content, Footer } = props;
  return (
    <div className={style.unauthLayout}>
      <Navbar />
      <div className={style.content}>
        <Content />
      </div>
      {Footer && (
        <div className={style.footer}>
          <Footer />
        </div>
      )}
    </div>
  );
}
