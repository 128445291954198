import { useRef } from 'react';
import style from './style.module.css';
import Image from 'next/image';
import searchIcon from 'public/images/icons/searchBlack.svg';
import fatArrowForwardWhiteIcon from 'public/images/icons/fatArrowForwardWhite.svg';
import Button from 'components/Button';
import { useSearch } from 'providers/SearchProvider';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';

interface Props {
  placeholderText?: string;
  readOnly?: boolean;
}

export default function SearchBox({
  readOnly = false,
  placeholderText = 'ما الذي تبحث عنه؟',
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const search = useSearch();
  const [user] = useAuth();

  return (
    <div className={style.formContainer}>
      <div className={style.inputBox}>
        <form
          className={style.form}
          onSubmit={(e) => {
            analytics.questionAsked(user, search?.searchForm.getSearchText());
            search?.searchForm.onSubmit(e);
          }}
        >
          <div className={style.formRow}>
            <div className={style.inputContainer}>
              <Image
                src={searchIcon}
                alt="Search Icon"
                className={style.searchIcon}
              />
              <input
                name="queryText"
                type="text"
                placeholder={placeholderText}
                className={style.input}
                readOnly={readOnly}
                onChange={(e) => search?.searchForm.onTextChanged(e)}
                value={search?.searchForm.getSearchText()}
                ref={inputRef}
              />
            </div>
            <Button type="submit" variant="primary" className={style.submitBtn}>
              <Image
                src={fatArrowForwardWhiteIcon}
                alt="Forward arrow icon"
                className={style.submitIcon}
              />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
