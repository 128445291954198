import { ChangeEvent, useState, ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import visibility_off from '../../public/images/icon-visibility_off.svg';
import visibility_on from '../../public/images/icon-visibility_on.svg';
import errorRed from '../../public/images/icons/errorRed.svg';

import style from './style.module.css';
import ErrorMessage from 'components/ErrorMessage';

interface Props extends ComponentPropsWithoutRef<'input'> {
  type: string;
  name: string;
  placeholder?: string | undefined;
  value: string;
  onChangeCB: (ev: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  hasError?: boolean;
  errorMessage?: string;
}

export default function Input({
  type,
  name,
  placeholder,
  value,
  onChangeCB,
  className = '',
  hasError,
  errorMessage,
  ...attr
}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const isTypePassword = type === 'password';
  const inputType = isTypePassword && isVisible ? 'text' : type;
  const handleVisibilityChange = () => {
    setIsVisible(!isVisible);
  };
  let autocomplete = '';
  switch (type) {
    case 'password':
      autocomplete = 'current-password';
      break;
    case 'email':
      autocomplete = 'username';
      break;
  }
  let marginBottom = '1.5rem';
  if (hasError) {
    marginBottom = Boolean(errorMessage) ? '0.75rem' : '0';
  }

  const offsetRight = hasError ? '3.5rem' : '1.5rem';

  const customStyle = {
    '--margin-bottom': marginBottom,
    '--right': offsetRight,
  } as React.CSSProperties;

  return (
    <div className={style.container} style={customStyle}>
      <div className={style.inputContainer}>
        <input
          type={inputType}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChangeCB}
          className={classNames(
            style.input,
            { [className]: Boolean(className) },
            { [style.inputWithError]: hasError },
          )}
          autoComplete={autocomplete}
          {...attr}
        />
        {isTypePassword && (
          <Image
            className={style.visibilityIcon}
            src={isVisible ? visibility_on : visibility_off}
            alt={`${isVisible ? 'Hide password' : 'Show password'}`}
            height={24}
            width={24}
            onClick={handleVisibilityChange}
          />
        )}
        {hasError && <Image alt="Error icon" src={errorRed} height={44} />}
      </div>
      {Boolean(errorMessage) && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}
