import style from './style.module.css';

interface QuestionTooltipProps {
  text: string;
}

export function QuestionTooltip(props: QuestionTooltipProps) {
  return (
    <div className={style.tooltipContainer}>
      <div className={style.bubble}>{props.text}</div>
    </div>
  );
}
